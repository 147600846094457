import { property } from 'lit/decorators';
import {
  css,
  html,
  NNBase,
  nothing,
} from '@mch/nn-web-viz/dist/packages/base/Base';

import '@vaadin/text-field';
import { xmark } from '../../../../assets/icons/xmark';

class AdeleManageAddUser extends NNBase {
  @property({ type: String }) _email: string = '';
  @property({ type: Boolean }) _showErrorMessage: boolean = false;

  static styles: any = [
    css`
      * {
        box-sizing: border-box;
      }

      .header-actions {
        display: flex;
        justify-content: end;
      }

      h1 {
        margin: 0.25rem 0;
      }

      .container {
        display: flex;
        flex-direction: column;
        width: 800px;
        height: 300px;
        box-sizing: border-box;
        border-radius: 15px;
        padding: 16px 19px;
        border-radius: 16px;
        background: rgba(28, 31, 40);
        box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
        backdrop-filter: blur(18px);
        color: #ffffff;
        font-weight: 800;
      }

      .new-source__wrapper {
        margin-top: 1rem;
      }

      #new-source {
        font-family: 'Nunito Sans';
        border-radius: 8px;
        border: 0px solid black;
        padding-left: 25px;
        padding-right: 25px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        width: 100%;
        height: 50px;
        font-size: 16px;
        backdrop-filter: blur(4px);
      }

      .error {
        color: red;
        font-size: 0.8rem;
      }

      .footer-actions {
        margin-top: auto;
        margin-bottom: 1rem;
      }

      .footer-actions nn-button {
        width: 100px;
      }
    `,
  ];

  _emailChanged() {
    const emailElement = this.shadowRoot?.getElementById(
      'new-source'
    ) as HTMLSelectElement;

    // const regexp = new RegExp('^[a-zA-Z0-9._%+-]+@90north.health$', 'g');
    const regexp = new RegExp(
      '^[a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
      'g'
    );
    const valid = regexp.test(emailElement.value);

    if (!valid) {
      this._showErrorMessage = true;
    } else {
      this._email = emailElement.value;
      this._showErrorMessage = false;
    }
  }

  _renderErrorMessage() {
    return html`
      <span class="error">Please provide a valid email address.</span>
    `;
  }

  _closeOverlay() {
    this.dispatchEvent(new Event('close-overlay', { bubbles: true }));

    const emailElement = this.shadowRoot?.getElementById(
      'new-source'
    ) as HTMLSelectElement;
    emailElement.value = '';
    this._email = '';
    this._showErrorMessage = false;
  }

  addClicked() {
    this.dispatchEvent(
      new CustomEvent('add-clicked', {
        bubbles: true,
        composed: true,
        detail: {
          email: this._email,
        },
      })
    );

    this._closeOverlay();
  }

  render() {
    return html` <div class="container">
      <div class="header-actions">
        <nn-button ghost @click=${this._closeOverlay}>
          <nn-icon .svg=${xmark}></nn-icon>
        </nn-button>
      </div>
      <h1>Add User</h1>

      <div class="new-source__wrapper">
        <label for="new-source">Enter email address</label>
        <input id="new-source" type="email" @change=${this._emailChanged} />
        ${this._showErrorMessage ? this._renderErrorMessage() : nothing}
      </div>

      <div class="footer-actions">
        <nn-button @click=${this.addClicked} ?disabled=${this._showErrorMessage}
          >Add</nn-button
        >
      </div>
    </div>`;
  }
}

export { AdeleManageAddUser };
