import { PropertyValues } from 'lit';
import { property, state } from 'lit/decorators';
import {
  CSSResult,
  html,
  NNBase,
  nothing,
} from '@mch/nn-web-viz/dist/packages/base/Base';

import '@mch/nn-web-viz/dist/nn-button';
import '@mch/nn-web-viz/dist/nn-icon';
import '@mch/nn-web-viz/dist/nn-spinner';

import {
  GridColumnBodyLitRenderer,
  columnBodyRenderer,
  gridRowDetailsRenderer,
} from '@vaadin/grid/lit';
import {
  registerStyles,
  css as vaadinCss,
} from '@vaadin/vaadin-themable-mixin';
import '@vaadin/grid';
import '@vaadin/text-field';
import '@vaadin/multi-select-combo-box';
import '@vaadin/checkbox';
import '@vaadin/date-picker';
import { Notification } from '@vaadin/notification';
import type { DatePickerChangeEvent } from '@vaadin/date-picker';

import { trackPageView } from '@mch/nn-web-viz/dist/packages/analytics';
import { plus } from '../../../assets/icons/plus';
import { penToSquare } from '../../../assets/icons/penToSquare';

import { connect, store } from '../../../state/store';
import { authFunctionsClient } from '../../../modules/functions/client';

import { vaadinStyles } from '../../../assets/styles/vaadinStyles';
import { AdeleManageStyles } from './AdeleManageStyles';
import './components/adele-manage-add-user';
import { getClaimsAccounts } from '../../../modules/claims';
import {
  Account,
  AccountState,
  setAccounts,
  setCurrentAccount,
} from '../../../state/slices/accounts';

registerStyles('vaadin-multi-select-combo-box-overlay', [
  vaadinCss`
     [part='overlay'] {
        color: white;
        background: #181B30;
      }
    `,
]);

const getFormattedDate = date => {
  const currentDate = new Date(date);
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

class AdeleManage extends connect(store)(NNBase) {
  @property({ type: String }) _accountDescription: string = '';

  @property({ type: String }) _accountName: string = '';

  @property({ type: Object }) _accountToEdit: any | null = null;

  @property({ type: Array }) _agents: Array<any> = [];

  @property({ type: Array }) _existingData: Array<any> = [];

  @property({ type: Array }) _detailsOpenedItems: Array<any> = [];

  @property({ type: Array }) _documents: Array<any> = [];

  @property({ type: Array }) _products: Array<any> = [];

  @property({ type: Array }) _sources: Array<any> = [];

  @property({ type: Boolean }) _isAdmin: boolean = false;

  @property({ type: Boolean }) _isEditingAccount: boolean = false;

  @property({ type: Boolean }) _loadingAdminData: boolean = false;

  @property({ type: Array }) _models: Array<any> = [];

  @property({ type: Boolean }) _savingData: boolean = false;

  @property({ type: Array }) _selectedAgents: Array<any> = [];

  @property({ type: Array }) _selectedDocuments: Array<any> = [];

  @property({ type: Array }) _selectedProducts: Array<any> = [];

  @property({ type: Array }) _selectedModels: Array<any> = [];

  @property({ type: Array }) _selectedSources: Array<any> = [];

  @property({ type: Array }) _users: Array<any> = [];

  @property({ type: Object }) _currentAccount: any | null;

  @state() private _accounts: Array<Account> = [];

  private _usersCache: Array<any> = [];

  static styles: CSSResult[] = [...vaadinStyles, ...AdeleManageStyles];

  constructor() {
    super();

    this._getAdminData();
  }

  stateChanged(_state: { accounts: AccountState }): void {
    this._isAdmin = _state.accounts.currentAccount?.isAdmin || false;
    this._currentAccount = _state.accounts.currentAccount;
    this._accounts = _state.accounts.accounts;
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    trackPageView({ page: 'MANAGE_PAGE' });
  }

  async _getAdminData() {
    try {
      this._loadingAdminData = true;

      const result = await authFunctionsClient.getAdminData(
        store.getState().accounts.currentAccount?.id
      );

      if (result != null) {
        this._sources =
          result?.all_assets?.sources?.map(source => ({
            label: source?.name || 'Unnamed Source',
            value: source?.id || -1,
          })) || [];
        this._agents =
          result?.all_assets?.agents?.map(agent => ({
            label: agent?.name || 'Unnamed Agent',
            value: agent?.id || -1,
          })) || [];
        this._documents =
          result?.all_assets?.documents?.map(document => ({
            label: document?.title || 'Unnamed Document',
            value: document?.id || -1,
          })) || [];
        this._products =
          result?.all_assets?.products?.map(product => ({
            label: product?.name || 'Unnamed Product',
            value: product?.id || -1,
          })) || [];
        this._models =
          result?.all_assets?.models?.map(model => ({
            label: model?.name || 'Unnamed Model',
            value: model?.id || -1,
          })) || [];
        this._existingData = result?.existing_data || [];
      }
    } catch (ex) {
      console.error('Error getting admin data', ex);
    } finally {
      this._loadingAdminData = false;
    }
  }

  _editAccountRenderer: GridColumnBodyLitRenderer<any> = column => {
    const data: any = column;

    return html`
      <div
        style="display: flex; gap: 0.5rem; align-items: center; font-weight: bold; cursor: pointer;"
        @click=${() => this._editAccountClicked(data.id)}
        @keydown=${() => this._editAccountClicked(data.id)}
      >
        <span>edit account</span><nn-icon .svg=${penToSquare}></nn-icon>
      </div>
    `;
  };

  _editAccountClicked(id) {
    this._isEditingAccount = true;
    this._accountToEdit = this._existingData.find(account => account.id === id);

    const selectedSources =
      this._accountToEdit?.sources.map(source => source.id) || [];
    this._selectedSources = this._sources.filter(source =>
      selectedSources.includes(source.value)
    );

    const selectedAgents =
      this._accountToEdit?.agents.map(agent => agent.id) || [];
    this._selectedAgents = this._agents.filter(agent =>
      selectedAgents.includes(agent.value)
    );

    const selectedDocuments =
      this._accountToEdit?.documents.map(document => document.id) || [];
    this._selectedDocuments = this._documents.filter(document =>
      selectedDocuments.includes(document.value)
    );

    const selectedProducts =
      this._accountToEdit?.products.map(product => product.id) || [];
    this._selectedProducts = this._products.filter(product =>
      selectedProducts.includes(product.value)
    );

    const selectedModels =
      this._accountToEdit?.models.map(model => model.id) || [];
    this._selectedModels = this._models.filter(model =>
      selectedModels.includes(model.value)
    );

    this._users = this._accountToEdit?.users;
    this._usersCache = this._accountToEdit?.users;
  }

  _editClicked(data) {
    const isOpened = this._detailsOpenedItems.includes(data);
    this._detailsOpenedItems = isOpened
      ? this._detailsOpenedItems.filter(p => p !== data)
      : [...this._detailsOpenedItems, data];
  }

  _editUserRenderer: GridColumnBodyLitRenderer<any> = column => {
    const data: any = column;

    return html`
      <div
        style="display: flex; gap: 0.5rem; align-items: center; font-weight: bold; cursor: pointer;"
        @click="${() => this._editClicked(data)}"
        @keydown="${() => this._editClicked(data)}"
      >
        <span>edit permission</span><nn-icon .svg=${penToSquare}></nn-icon>
      </div>
    `;
  };

  _backToPreviousScreen() {
    this._isEditingAccount = false;

    this._refreshClaims();
    this._resetCache();
    this._getAdminData();
  }

  async _refreshClaims() {
    const resultClaimsAccounts = await getClaimsAccounts();

    if (resultClaimsAccounts.length !== 0) {
      store.dispatch(setAccounts(resultClaimsAccounts));
      store.dispatch(
        setCurrentAccount(
          resultClaimsAccounts.find(acc => acc.id === this._currentAccount.id)
        )
      );
    }
  }

  // START SOURCES
  _renderSources() {
    return html`
      <vaadin-multi-select-combo-box
        name="sources"
        label="Sources"
        item-id-path="value"
        item-label-path="label"
        placeholder="Sources"
        .items=${this._sources}
        .selectedItems=${this._selectedSources}
        @selected-items-changed=${this._selectedSourcesChanged}
      ></vaadin-multi-select-combo-box>
    `;
  }

  _selectedSourcesChanged({ detail: { value } }) {
    this._selectedSources = value;
  }
  // END SOURCES

  // START DOCUMENTS
  _renderDocuments() {
    return html`
      <vaadin-multi-select-combo-box
        name="documents"
        label="Documents"
        item-id-path="value"
        item-label-path="label"
        placeholder="Documents"
        .items=${this._documents}
        .selectedItems=${this._selectedDocuments}
        @selected-items-changed=${this._selectedDocumentsChanged}
      ></vaadin-multi-select-combo-box>
    `;
  }

  _selectedDocumentsChanged({ detail: { value } }) {
    this._selectedDocuments = value;
  }
  // END DOCUMENTS

  // START PRODUCTS
  _renderProducts() {
    return html`
      <vaadin-multi-select-combo-box
        name="products"
        label="Products"
        item-id-path="value"
        item-label-path="label"
        placeholder="Products"
        .items=${this._products}
        .selectedItems=${this._selectedProducts}
        @selected-items-changed=${this._selectedProductsChanged}
      ></vaadin-multi-select-combo-box>
    `;
  }

  _selectedProductsChanged({ detail: { value } }) {
    this._selectedProducts = value;
  }
  // END DOCUMENTS

  // START MODELS
  _renderModels() {
    return html`
      <vaadin-multi-select-combo-box
        name="models"
        label="Models"
        item-id-path="value"
        item-label-path="label"
        placeholder="Models"
        .items=${this._models}
        .selectedItems=${this._selectedModels}
        @selected-items-changed=${this._selectedModelsChanged}
      ></vaadin-multi-select-combo-box>
    `;
  }

  _selectedModelsChanged({ detail: { value } }) {
    this._selectedModels = value;
  }
  // END MODE

  // START AGENTS
  _renderAgents() {
    return html`
      <vaadin-multi-select-combo-box
        name="agents"
        label="Agents"
        item-id-path="value"
        item-label-path="label"
        placeholder="Agents"
        .items=${this._agents}
        .selectedItems=${this._selectedAgents}
        @selected-items-changed=${this._selectedAgentsChanged}
      ></vaadin-multi-select-combo-box>
    `;
  }

  _selectedAgentsChanged({ detail: { value } }) {
    this._selectedAgents = value;
  }
  // END AGENTS

  async _createAccount() {
    try {
      this._savingData = true;

      const payload = {
        name: this._accountName,
        description: this._accountDescription,
        sources: this._selectedSources.map(source => source.value),
        models: this._selectedModels.map(model => model.value),
        documents: this._selectedDocuments.map(document => document.value),
        products: this._selectedProducts.map(product => product.value),
        agents: this._selectedAgents.map(agent => agent.value),
        users: this._usersCache,
      };

      const result = await authFunctionsClient.saveAdminData(
        payload,
        store.getState().accounts.currentAccount?.id
      );

      if (result != null) {
        setTimeout(() => {
          this._backToPreviousScreen();
          this._getAdminData();
        }, 1500);

        Notification.show('Account created successfully!', {
          position: 'middle',
          duration: 2000,
        });
      }
    } catch (ex) {
      console.error('Error saving account', ex);
    } finally {
      this._savingData = false;
    }
  }

  async _updateAccount() {
    try {
      this._savingData = true;

      const payload = {
        account_id: this._accountToEdit.id,
        name: this._accountName,
        description: this._accountDescription,
        sources: this._selectedSources.map(source => source.value),
        models: this._selectedModels.map(model => model.value),
        documents: this._selectedDocuments.map(document => document.value),
        products: this._selectedProducts.map(product => product.value),
        agents: this._selectedAgents.map(agent => agent.value),
        users: this._usersCache,
      };

      const result = await authFunctionsClient.saveAdminData(
        payload,
        store.getState().accounts.currentAccount?.id
      );

      if (result != null) {
        Notification.show('Account updated successfully!', {
          position: 'middle',
          duration: 2000,
        });
        this._refreshClaims();
      }
    } catch (ex) {
      console.error('Error updating account', ex);
    } finally {
      this._savingData = false;
    }
  }

  _resetCache() {
    this._accountToEdit = null;
    this._selectedSources = [];
    this._selectedDocuments = [];
    this._selectedModels = [];
    this._selectedAgents = [];
    this._accountName = '';
    this._accountDescription = '';
    this._users = [];
    this._usersCache = [];
  }

  _accountNameChanged({ detail: { value } }) {
    this._accountName = value;
  }

  _descriptionChanged({ detail: { value } }) {
    this._accountDescription = value;
  }

  _addClicked(e) {
    const currentEmails = this._users.map(user => user.email.toLowerCase());

    if (currentEmails.includes(e.detail.email.toLowerCase())) {
      Notification.show(`${e.detail.email} already exists`, {
        position: 'middle',
        duration: 2000,
      });
    } else {
      const newUser = {
        email: e.detail.email,
        is_admin: false,
        valid_until: `${getFormattedDate(new Date())}T00:00:00+00:00`,
        can_upload: false,
      };

      this._users = [...this._users, newUser];
      this._usersCache = [...this._usersCache, newUser];
    }
  }

  _addUserOverlay() {
    return html`
      <nn-overlay class="overlay">
        <nn-button slot="invoker"
          ><span>ADD NEW USER</span><nn-icon .svg=${plus}></nn-icon
        ></nn-button>
        <adele-manage-add-user
          slot="content"
          @add-clicked=${this._addClicked}
        ></adele-manage-add-user>
      </nn-overlay>
    `;
  }

  _renderEditAccount() {
    return html` <div class="container">
      <div class="body-container">
        <div class="header-container">
          <h1>Manage Accounts / Edit Account</h1>
        </div>
        <div class="action-container">
          <nn-button
            ghost
            style="text-decoration: none;"
            .textTransformNone=${true}
            @click=${this._backToPreviousScreen}
            ><span>Back to previous screen</span></nn-button
          >
        </div>

        <div class="form-container">
          <div class="column">
            <vaadin-text-field
              label="Account Name"
              placeholder="Account Name"
              value="${this._accountToEdit?.name || ''}"
              @value-changed=${this._accountNameChanged}
            ></vaadin-text-field>

            ${this._renderSources()} ${this._renderDocuments()}
            ${this._renderProducts()}
          </div>
          <div class="column">
            <vaadin-text-field
              label="Account Description"
              placeholder="Account Description"
              value="${this._accountToEdit?.description ||
              this._accountToEdit?.name ||
              ''}"
              @value-changed=${this._descriptionChanged}
            ></vaadin-text-field>

            ${this._renderModels()} ${this._renderAgents()}
          </div>
        </div>
        <div class="new-user__wrapper">${this._addUserOverlay()}</div>
        <div class="user-table-container">
          <vaadin-grid
            style="border-radius:1rem;overflow:hidden;"
            theme="row-stripes"
            .items="${this._users}"
            .detailsOpenedItems=${this._detailsOpenedItems}
            ${gridRowDetailsRenderer<any>(
              user =>
                html`
                  <div class="form-wrapper">
                    <vaadin-checkbox
                      value="true"
                      label="Can upload"
                      .checked=${user.can_upload}
                      @change=${e => {
                        this._usersCache = this._usersCache.map(u => {
                          if (u.email === user.email) {
                            return {
                              ...u,
                              can_upload: e.target.checked,
                            };
                          }
                          return u;
                        });
                      }}
                    ></vaadin-checkbox>
                     <vaadin-checkbox
                          value="true"
                          label="Is admin"
                          .checked=${user.is_admin}
                          @change=${e => {
                            this._usersCache = this._usersCache.map(u => {
                              if (u.email === user.email) {
                                return {
                                  ...u,
                                  is_admin: e.target.checked,
                                };
                              }
                              return u;
                            });
                          }}
                        ></vaadin-checkbox>
                      </vaadin-form-layout>
                    <vaadin-date-picker
                      label="Valid until"
                      value=${getFormattedDate(user.valid_until)}
                      @change="${({ target }: DatePickerChangeEvent) => {
                        this._usersCache = this._usersCache.map(u => {
                          if (u.email === user.email) {
                            return {
                              ...u,
                              valid_until: `${target.value}T00:00:00+00:00}`,
                            };
                          }
                          return u;
                        });
                      }}"
                    ></vaadin-date-picker>
                  </div>
                `,
              []
            )}
          >
            <vaadin-grid-column
              header="Email"
              auto-width
              path="email"
            ></vaadin-grid-column>
            <vaadin-grid-column
              ${columnBodyRenderer(this._editUserRenderer, [])}
            ></vaadin-grid-column>

            <span slot="empty-state">No users found.</span>
          </vaadin-grid>
        </div>

        <div class="footer-actions">
          ${this._accountToEdit
            ? html`<nn-button
                @click=${this._updateAccount}
                ?disabled=${this._savingData}
                >${this._savingData ? 'Updating...' : 'Update'}</nn-button
              >`
            : html` <nn-button
                @click=${this._createAccount}
                ?disabled=${this._savingData}
                >${this._savingData ? 'Saving...' : 'Save'}</nn-button
              >`}
        </div>
      </div>
    </div>`;
  }

  _addAccountClicked() {
    this._isEditingAccount = true;

    this._resetCache();
  }

  render() {
    if (!this._isAdmin)
      return html` <div class="container">
        <div class="body-container">
          <h1>You are not allowed to access this page</h1>
        </div>
      </div>`;

    return this._isEditingAccount
      ? this._renderEditAccount()
      : html`<div class="container">
          <div class="body-container">
            <div class="header-container">
              <h1>Manage Accounts</h1>
            </div>
            <div class="action-container">
              <nn-button @click=${this._addAccountClicked}
                ><span>ADD ACCOUNT</span><nn-icon .svg=${plus}></nn-icon
              ></nn-button>
            </div>

            ${this._loadingAdminData
              ? html`<nn-spinner theme="default"></nn-spinner>`
              : html`
                  <div class="table-container">
                    ${this._existingData.length !== 0
                      ? html`<vaadin-grid
                          style="border-radius:1rem;overflow:hidden;"
                          theme="row-stripes"
                          .items="${this._existingData}"
                        >
                          <vaadin-grid-column
                            header="Account"
                            auto-width
                            path="name"
                          ></vaadin-grid-column>
                          <vaadin-grid-column
                            header="Description"
                            path="name"
                          ></vaadin-grid-column>
                          <vaadin-grid-column
                            ${columnBodyRenderer(this._editAccountRenderer, [])}
                          ></vaadin-grid-column>
                        </vaadin-grid>`
                      : nothing}
                  </div>
                `}
          </div>
        </div> `;
  }
}

export { AdeleManage };
