import { css } from 'lit';

const AdeleManageStyles = [
  css`
    :host {
      color: white;
    }

    h1 {
      font-family: Nunito Sans;
      font-size: 32px;
      font-weight: 400;
      line-height: 38.4px;
      text-align: left;
    }

    * {
      box-sizing: border-box;
    }

    .container {
      border-radius: 24px;

      background: linear-gradient(
        237deg,
        rgba(28, 31, 40, 0.1) 5.65%,
        rgba(28, 31, 40, 0.3) 85.87%,
        rgba(28, 31, 40, 0.5) 97.63%
      );
      box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(18px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      gap: 10px;
    }

    .body-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      padding: 1rem;
    }

    nn-icon {
      width: 20px;
      height: 20px;
    }

    .form-container {
      width: 50%;
      display: flex;
      gap: 1rem;
    }

    .column {
      flex: 50%;
    }

    nn-spinner {
      margin-top: 2rem;
    }

    .table-container {
      margin-top: 2rem;
    }

    .new-user__wrapper {
      margin: 0.5rem 0;
    }

    .user-table-container {
      height: 100%;
      min-height: 200px;
      margin: 0 0 1rem;
    }

    .user-table-container vaadin-grid {
      height: 100%;
    }

    .form-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: baseline;
      gap: 2rem;
      padding: 0.25rem 1rem;
    }

    .footer-actions {
      margin-top: auto;
    }

    .footer-actions nn-button {
      width: 100px;
    }

    vaadin-text-field,
    vaadin-select,
    vaadin-multi-select-combo-box {
      width: 100%;
    }

    @media only screen and (max-width: 1248px) {
      .form-container {
        width: 100%;
      }
    }
  `,
];

export { AdeleManageStyles };
